<template>
  <div>
    <v-list v-if="$vuetify.breakpoint.xs">
      <v-data-iterator
        disable-pagination
        hide-default-footer
        :items="items"
        item-key="id"
        sort-by="student"
        disable-sort
        disable-filtering
      >
        <template slot="no-data"
          ><v-card-text class="py-0 text--disabled"
            >keine Daten vorhanden</v-card-text
          ></template
        >
        <v-list-item
          v-for="item in items"
          :key="item.name"
          :to="{
            name: 'AbsencesOverviewStudent',
            params: { id: item.student.id },
          }"
        >
          <v-list-item-avatar
            ><PortraitImage :value="item.student"></PortraitImage
          ></v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              ><PersonName :value="item.student"></PersonName
            ></v-list-item-title>
            <v-list-item-subtitle>
              <v-badge
                v-if="tooOld(item.absences[0])"
                color="danger"
                dot
                inline
              >
                <DateSpan
                  :value="[
                    item.absences[0].date,
                    item.absences[item.absences.length - 1].date,
                  ]"
                ></DateSpan>
              </v-badge>
              <DateSpan
                v-else
                :value="[
                  item.absences[0].date,
                  item.absences[item.absences.length - 1].date,
                ]"
              ></DateSpan>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-chip> {{ item.absences.length }}</v-chip>
          </v-list-item-action>
        </v-list-item>
      </v-data-iterator>
    </v-list>
    <v-data-table
      v-else
      disable-pagination
      hide-default-footer
      :headers="headers"
      :items="items"
      item-key="id"
      :item-class="() => 'clickable'"
      sort-by="student"
      @click:row="
        (el) =>
          $router.push({
            name: 'AbsencesOverviewStudent',
            params: { id: el.student.id },
          })
      "
      disable-sort
      disable-filtering
    >
      <template v-slot:item.student="{ item }">
        <PersonItem :value="item.student" small />
      </template>
      <template v-slot:item.count="{ item }">
        {{ item.absences.length }}
      </template>

      <template v-slot:item.firstDate="{ item }">
        <v-badge v-if="tooOld(item.absences[0])" color="danger" dot inline>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <DateValue
                v-bind="attrs"
                v-on="on"
                :value="item.absences[0].date"
                long
              />
            </template>
            <span>{{
              isToday(item.absences[0])
                ? "heute"
                : `vor ${age(item.absences[0].date)} Tagen`
            }}</span>
          </v-tooltip>
        </v-badge>
        <DateValue v-else :value="item.absences[0].date" long />
      </template>

      <template v-slot:item.lastDate="{ item }">
        <v-badge
          v-if="tooOld(item.absences[item.absences.length - 1])"
          color="danger"
          dot
          inline
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <DateValue
                v-bind="attrs"
                v-on="on"
                :value="item.absences[item.absences.length - 1].date"
                long
              />
            </template>
            <span>{{
              isToday(item.absences[item.absences.length - 1])
                ? "heute"
                : `vor ${age(item.absences[item.absences.length - 1])} Tagen`
            }}</span>
          </v-tooltip>
        </v-badge>
        <DateValue
          v-else
          :value="item.absences[item.absences.length - 1].date"
          long
        />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import DateSpan from "common/components/DateSpan.vue";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "@/components/PersonItem.vue";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";

import { comparePeople } from "common/utils/people.js";
import { age } from "common/utils/date.js";

export default {
  components: { DateSpan, DateValue, PersonItem, PersonName, PortraitImage },
  props: { items: [] },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "student",
          sort: (a, b) => comparePeople(a, b),
        },
        {
          text: "erste",
          value: "firstDate",
        },
        {
          text: "letzte",
          value: "lastDate",
        },
        {
          text: "Lektionen",
          value: "count",
        },
      ],
    };
  },

  methods: {
    age,
    isToday(item) {
      return this.age(item.date) == 0;
    },
    tooOld(item) {
      return item.status.code == "pending" && this.age(item.date) > 8;
    },
  },
  created() {},
};
</script>
