var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$vuetify.breakpoint.xs)?_c('v-list',[_c('v-data-iterator',{attrs:{"disable-pagination":"","hide-default-footer":"","items":_vm.items,"item-key":"id","sort-by":"student","disable-sort":"","disable-filtering":""}},[_c('template',{slot:"no-data"},[_c('v-card-text',{staticClass:"py-0 text--disabled"},[_vm._v("keine Daten vorhanden")])],1),_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.name,attrs:{"to":{
          name: 'AbsencesOverviewStudent',
          params: { id: item.student.id },
        }}},[_c('v-list-item-avatar',[_c('PortraitImage',{attrs:{"value":item.student}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('PersonName',{attrs:{"value":item.student}})],1),_c('v-list-item-subtitle',[(_vm.tooOld(item.absences[0]))?_c('v-badge',{attrs:{"color":"danger","dot":"","inline":""}},[_c('DateSpan',{attrs:{"value":[
                  item.absences[0].date,
                  item.absences[item.absences.length - 1].date,
                ]}})],1):_c('DateSpan',{attrs:{"value":[
                item.absences[0].date,
                item.absences[item.absences.length - 1].date,
              ]}})],1)],1),_c('v-list-item-action',[_c('v-chip',[_vm._v(" "+_vm._s(item.absences.length))])],1)],1)})],2)],1):_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"item-key":"id","item-class":() => 'clickable',"sort-by":"student","disable-sort":"","disable-filtering":""},on:{"click:row":(el) =>
        _vm.$router.push({
          name: 'AbsencesOverviewStudent',
          params: { id: el.student.id },
        })},scopedSlots:_vm._u([{key:"item.student",fn:function({ item }){return [_c('PersonItem',{attrs:{"value":item.student,"small":""}})]}},{key:"item.count",fn:function({ item }){return [_vm._v(" "+_vm._s(item.absences.length)+" ")]}},{key:"item.firstDate",fn:function({ item }){return [(_vm.tooOld(item.absences[0]))?_c('v-badge',{attrs:{"color":"danger","dot":"","inline":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('DateValue',_vm._g(_vm._b({attrs:{"value":item.absences[0].date,"long":""}},'DateValue',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.isToday(item.absences[0]) ? "heute" : `vor ${_vm.age(item.absences[0].date)} Tagen`))])])],1):_c('DateValue',{attrs:{"value":item.absences[0].date,"long":""}})]}},{key:"item.lastDate",fn:function({ item }){return [(_vm.tooOld(item.absences[item.absences.length - 1]))?_c('v-badge',{attrs:{"color":"danger","dot":"","inline":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('DateValue',_vm._g(_vm._b({attrs:{"value":item.absences[item.absences.length - 1].date,"long":""}},'DateValue',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.isToday(item.absences[item.absences.length - 1]) ? "heute" : `vor ${_vm.age(item.absences[item.absences.length - 1])} Tagen`))])])],1):_c('DateValue',{attrs:{"value":item.absences[item.absences.length - 1].date,"long":""}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }